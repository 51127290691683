<div
  [cTooltip]="'ui.app.ccf.configurator.end-module-side.end-module-side-component-html.end-module-tooltip' | translate"
  class="module"
>
  <ng-container *ngIf="configuration?.end_module_type !== CcfPartEndModuleType.End">
    <div class="d-flex align-items-center position-relative">
      <div (click)="changeModule()" [ngClass]="{ 'cursor-pointer': !readonly }" class="open-main"></div>

      <div
        (click)="changeAdapter()"
        [ngClass]="{
          'cursor-pointer': !readonly,
          'inlet-up': configuration?.end_module_type === CcfPartEndModuleType.Up,
          'inlet-down': configuration?.end_module_type === CcfPartEndModuleType.Down,
          'blank text-white blink': !configuration?.end_module_adapter
        }"
        class="open-inlet text-center text-small text-truncate rotate"
      >
        <span *ngIf="!configuration?.end_module_adapter" translate>
          ui.app.ccf.configurator.end-module-side.end-module-side-component-html.select
        </span>
      </div>

      <span *ngIf="dimensions.edgeModuleLength" class="measure-side-end">
        {{ dimensions.edgeModuleLength }}
      </span>
    </div>

    <div class="d-flex flex-column position-relative">
      <span
        (click)="changeAdapter()"
        *ngIf="configuration?.end_module_adapter && configuration?.end_module_adapter !== module?.size"
        [ngClass]="{
          'cursor-pointer': !readonly,
          'adapter-125': configuration?.end_module_adapter === CcfPartEndModuleAdapter.NUMBER_125
        }"
        class="adapter text-center text-small text-truncate rotate"
      >
        {{ configuration?.end_module_adapter }}
      </span>

      <span
        *ngIf="configuration?.end_module_adapter && configuration?.end_module_adapter !== module?.size"
        [ngClass]="
          configuration?.end_module_adapter === CcfPartEndModuleAdapter.NUMBER_125
            ? 'measure-side-end-adapter-125'
            : 'measure-side-end-adapter'
        "
      >
        {{ dimensions.adapterLength }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="configuration?.end_module_type === CcfPartEndModuleType.End">
    <div class="d-flex flex-column position-relative">
      <div (click)="changeModule()" [ngClass]="{ 'cursor-pointer': !readonly }" class="closed-main">
        <div class="end-module d-flex align-items-center">{{ dimensions.endModuleHeight }}</div>
      </div>

      <span class="measure-side-end-3">
        {{ dimensions.endModuleLength }}
      </span>
    </div>
  </ng-container>

  <div
    *ngIf="[CcfPartEndModuleType.Up, CcfPartEndModuleType.Down].indexOf(configuration?.end_module_type) !== -1"
    [ngClass]="{
      'inlet-up': configuration?.end_module_type === CcfPartEndModuleType.Up,
      'inlet-down': configuration?.end_module_type === CcfPartEndModuleType.Down
    }"
    class="adapter-position-end d-flex align-items-center"
  >
    {{ dimensions.frontModuleHeight }}
  </div>
</div>
