import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CcfConfiguration, CcfPart, CcfPartMedia, Media } from '@api';
import { ModuleTileComponent } from './module-tile/module-tile.component';
import { DescriptionService } from '@core';

interface ModulesMap<T> {
  module_2_2_2_o_o?: T;
  module_2_2_0_o_o?: T;
  module_2_0_2_o_o?: T;
  module_2_0_0_o_o?: T;
  module_2_2_2_b_o?: T;
  module_2_2_0_b_o?: T;
  module_2_0_2_b_o?: T;
  module_2_0_0_b_o?: T;
  module_2_0_0_b_b?: T;
  module_3_3_3_o_o?: T;
  module_3_0_3_o_o?: T;
  module_3_3_0_o_o?: T;
  module_3_0_0_o_o?: T;
  module_3_3_3_b_b?: T;
  module_3_0_3_b_b?: T;
  module_3_3_0_b_b?: T;
  module_3_3_3_b_o?: T;
  module_3_0_3_b_o?: T;
  module_3_3_0_b_o?: T;
  module_3_3_3_o_b?: T;
  module_3_0_3_o_b?: T;
  module_3_3_0_o_b?: T;
}

@Component({
  selector: 'app-ccf-configurator-module-selection',
  templateUrl: './module-selection.component.html',
  styleUrls: ['./module-selection.component.scss'],
})
export class ModuleSelectionComponent implements OnChanges {
  @Input() modules: (CcfPart | CcfConfiguration)[];
  @Input() mapped = false;
  @Input() search = false;

  @Output() selected: EventEmitter<CcfPart | CcfConfiguration> = new EventEmitter<CcfPart | CcfConfiguration>();

  public medias: { [key: string]: CcfPartMedia | Media } = {};
  public names: { [key: string]: string } = {};

  public searchText = '';
  public results: (CcfPart | CcfConfiguration)[];
  public modulesMap: ModulesMap<CcfPart | CcfConfiguration>;

  constructor(public descriptionService: DescriptionService) {}

  ngOnChanges(): void {
    this.medias = {};
    this.names = {};
    this.modules.forEach((module) => {
      const key =
        (module as CcfPart).code || (module as CcfConfiguration).article_number || (module as CcfConfiguration).id;

      this.medias[key] = ModuleTileComponent.getMedia(module);

      this.names[key] = ModuleTileComponent.getName(module, this.descriptionService);
    });

    this.searchText = '';
    this.results = this.modules;

    if (this.mapped) {
      const finder = (
        size: number,
        right_connections: boolean,
        left_connections: boolean,
        first_main_connection: boolean,
        second_main_connection: boolean,
      ) =>
        (this.modules as CcfPart[]).find(
          (module) =>
            module.size === size &&
            !module.left_connections === !left_connections &&
            !module.right_connections === !right_connections &&
            !module.first_main_connection === !first_main_connection &&
            !module.second_main_connection === !second_main_connection,
        );
      this.modulesMap = {
        module_2_2_2_o_o: finder(2, true, true, false, false),
        module_2_2_0_o_o: finder(2, true, false, false, false),
        module_2_0_2_o_o: finder(2, false, true, false, false),
        module_2_0_0_o_o: finder(2, false, false, false, false),
        module_2_2_2_b_o: finder(2, true, true, true, false),
        module_2_2_0_b_o: finder(2, true, false, true, false),
        module_2_0_2_b_o: finder(2, false, true, true, false),
        module_2_0_0_b_o: finder(2, false, false, true, false),
        module_2_0_0_b_b: finder(2, false, false, true, true),
        module_3_3_3_o_o: finder(3, true, true, false, false),
        module_3_0_3_o_o: finder(3, false, true, false, false),
        module_3_3_0_o_o: finder(3, true, false, false, false),
        module_3_0_0_o_o: finder(3, false, false, false, false),
        module_3_3_3_b_b: finder(3, true, true, true, true),
        module_3_0_3_b_b: finder(3, false, true, true, true),
        module_3_3_0_b_b: finder(3, true, false, true, true),
        module_3_3_3_b_o: finder(3, true, true, true, false),
        module_3_0_3_b_o: finder(3, false, true, true, false),
        module_3_3_0_b_o: finder(3, true, false, true, false),
        module_3_3_3_o_b: finder(3, true, true, false, true),
        module_3_0_3_o_b: finder(3, false, true, false, true),
        module_3_3_0_o_b: finder(3, true, false, false, true),
      };
    }
  }

  select(module: CcfPart | CcfConfiguration) {
    this.selected.emit(module);
  }

  filter(search: string = null) {
    if (search !== null) {
      this.searchText = search;
    }
    search = this.searchText.toLowerCase();
    this.results = this.modules.filter((module) => {
      const name = ModuleTileComponent.getName(module, this.descriptionService);
      return !search || (name && name.toLowerCase().indexOf(search) !== -1);
    });
  }
}
