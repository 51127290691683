<div
  [cTooltip]="
    'ui.app.ccf.configurator.front-module-side.front-module-side-component-html.front-module-tooltip' | translate
  "
  class="module"
>
  <div
    *ngIf="[CcfPartEndModuleType.Up, CcfPartEndModuleType.Down].indexOf(configuration?.front_module_type) !== -1"
    [ngClass]="{
      'inlet-up': configuration?.front_module_type === CcfPartEndModuleType.Up,
      'inlet-down': configuration?.front_module_type === CcfPartEndModuleType.Down
    }"
    class="adapter-position-front d-flex align-items-center"
  >
    {{ dimensions.frontModuleHeight }}
  </div>
  <ng-container *ngIf="configuration?.front_module_type !== CcfPartEndModuleType.End">
    <div class="d-flex flex-column position-relative">
      <span
        (click)="changeAdapter()"
        *ngIf="configuration?.front_module_adapter && configuration?.front_module_adapter !== module?.size"
        [ngClass]="{
          'cursor-pointer': !readonly,
          'adapter-125': configuration?.front_module_adapter === CcfPartEndModuleAdapter.NUMBER_125
        }"
        class="adapter text-center text-small text-truncate rotate"
      >
        {{ configuration?.front_module_adapter }}
      </span>

      <span
        *ngIf="configuration?.front_module_adapter && configuration?.front_module_adapter !== module?.size"
        [ngClass]="
          configuration?.front_module_adapter === CcfPartEndModuleAdapter.NUMBER_125
            ? 'measure-side-adapter-125'
            : 'measure-side-adapter'
        "
      >
        {{ dimensions.adapterLength }}
      </span>
    </div>
    <div class="d-flex align-items-center position-relative">
      <div
        (click)="changeAdapter()"
        [ngClass]="{
          'cursor-pointer': !readonly,
          'inlet-up': configuration?.front_module_type === CcfPartEndModuleType.Up,
          'inlet-down': configuration?.front_module_type === CcfPartEndModuleType.Down,
          'blank text-white blink': !configuration?.front_module_adapter
        }"
        class="open-inlet text-center text-small text-truncate rotate"
      >
        <span *ngIf="!configuration?.front_module_adapter" translate>
          ui.app.ccf.configurator.front-module-side.front-module-side-component-html.select
        </span>
      </div>

      <div
        (click)="changeModule()"
        [ngClass]="{ 'cursor-pointer': !readonly }"
        class="open-main d-flex align-items-center justify-content-center"
        translate
      >
        ui.app.ccf.configurator.front-module-side.front-module-side-component-html.a
      </div>

      <span *ngIf="dimensions.edgeModuleLength" class="measure-side">
        {{ dimensions.edgeModuleLength }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="configuration?.front_module_type === CcfPartEndModuleType.End">
    <div class="start-module d-flex align-items-center">{{ dimensions.endModuleHeight }}</div>
    <div class="d-flex flex-column position-relative">
      <div (click)="changeModule()" [ngClass]="{ 'cursor-pointer': !readonly }" class="closed-main"></div>
      <span class="measure-side-3">
        {{ dimensions.endModuleLength }}
      </span>
    </div>
  </ng-container>
</div>
