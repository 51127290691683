<div *ngIf="search" class="form-group">
  <div class="p-field">
    <span class="p-input-icon-left">
      <i class="pi pi-search"></i>
      <input
        (keyup)="filter()"
        (ngModelChange)="searchText = $event; filter()"
        [(ngModel)]="searchText"
        [placeholder]="'ui.app.ccf.configurator.module-selection.module-selection-component-html.search' | translate"
        pInputText
        type="search"
      />
    </span>
  </div>
</div>

<ng-container *ngIf="!mapped">
  <div (click)="select(module)" *ngFor="let module of results" class="module-image-container cursor-pointer">
    <img
      [attr.srcset]="medias[module.code || module.article_number || module.id]?.srcset"
      [src]="
        medias[module.code || module.article_number || module.id]
          ? medias[module.code || module.article_number || module.id].url
          : 'assets/image-placeholder.png'
      "
      class="module-image"
    />
    <div class="module-name">{{ names[module.code || module.article_number || module.id] }}</div>
  </div>
</ng-container>

<table *ngIf="mapped" class="table table-bordered">
  <thead>
    <tr>
      <th class="text-center align-top" rowspan="2" translate>
        ui.app.ccf.configurator.module-selection.module-selection-component-html.size-2-modules
      </th>
      <th class="text-center" colspan="4" translate>
        ui.app.ccf.configurator.module-selection.module-selection-component-html.side-connections
      </th>
    </tr>
    <tr>
      <th class="text-center">
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.right-left</div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.2-2</div>
      </th>
      <th class="text-center">
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.right</div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.2-0</div>
      </th>
      <th class="text-center">
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.left</div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-2</div>
      </th>
      <th class="text-center">
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.none</div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-0</div>
      </th>
    </tr>
  </thead>

  <tbody>
    <tr>
      <td>
        <div translate>
          ui.app.ccf.configurator.module-selection.module-selection-component-html.no-shaft-connection
        </div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-o</div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_2_2_o_o'])"
          [module]="modulesMap['module_2_2_2_o_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_2_2_o_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.2-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_2_0_o_o'])"
          [module]="modulesMap['module_2_2_0_o_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_2_0_o_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.2-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_0_2_o_o'])"
          [module]="modulesMap['module_2_0_2_o_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_0_2_o_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_0_0_o_o'])"
          [module]="modulesMap['module_2_0_0_o_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_0_0_o_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-o</span>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div translate>
          ui.app.ccf.configurator.module-selection.module-selection-component-html.bottom-shaft-connection
        </div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-o</div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_2_2_b_o'])"
          [module]="modulesMap['module_2_2_2_b_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_2_2_b_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.2-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_2_0_b_o'])"
          [module]="modulesMap['module_2_2_0_b_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_2_0_b_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.2-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_0_2_b_o'])"
          [module]="modulesMap['module_2_0_2_b_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_0_2_b_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_0_0_b_o'])"
          [module]="modulesMap['module_2_0_0_b_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_0_0_b_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-o</span>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div translate>
          ui.app.ccf.configurator.module-selection.module-selection-component-html.bottom-and-top-shaft-connections
        </div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-b</div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_2_2_b_b'])"
          [module]="modulesMap['module_2_2_2_b_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_2_2_b_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.2-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-b</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_2_0_b_b'])"
          [module]="modulesMap['module_2_2_0_b_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_2_0_b_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.2-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-b</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_0_2_b_b'])"
          [module]="modulesMap['module_2_0_2_b_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_0_2_b_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-b</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_2_0_0_b_b'])"
          [module]="modulesMap['module_2_0_0_b_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_2_0_0_b_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-2</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-b</span>
        </div>
      </td>
    </tr>
    <!--    <tr>-->
    <!--      <td>-->
    <!--        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.top-shaft-connection</div>-->
    <!--        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-b</div>-->
    <!--      </td>-->
    <!--      <td class="text-center align-middle">-->
    <!--        <app-ccf-configurator-module-tile-->
    <!--          [module]="modulesMap['module_2_2_2_o_b']"-->
    <!--          [impossible]="true"-->
    <!--          (click)="select(modulesMap['module_2_2_2_o_b'])"-->
    <!--        ></app-ccf-configurator-module-tile>-->
    <!--      </td>-->
    <!--      <td class="text-center align-middle">-->
    <!--        <app-ccf-configurator-module-tile-->
    <!--          [module]="modulesMap['module_2_2_0_o_b']"-->
    <!--          [impossible]="true"-->
    <!--          (click)="select(modulesMap['module_2_2_0_o_b'])"-->
    <!--        ></app-ccf-configurator-module-tile>-->
    <!--      </td>-->
    <!--      <td class="text-center align-middle">-->
    <!--        <app-ccf-configurator-module-tile-->
    <!--          [module]="modulesMap['module_2_0_2_o_b']"-->
    <!--          [impossible]="true"-->
    <!--          (click)="select(modulesMap['module_2_0_2_o_b'])"-->
    <!--        ></app-ccf-configurator-module-tile>-->
    <!--      </td>-->
    <!--      <td class="text-center align-middle">-->
    <!--        <app-ccf-configurator-module-tile-->
    <!--          [module]="modulesMap['module_2_0_0_o_b']"-->
    <!--          [impossible]="true"-->
    <!--          (click)="select(modulesMap['module_2_0_0_o_b'])"-->
    <!--        ></app-ccf-configurator-module-tile>-->
    <!--      </td>-->
    <!--    </tr>-->
  </tbody>

  <tr>
    <td colspan="5">
      <!-- Spacer -->
    </td>
  </tr>

  <thead>
    <tr>
      <th class="text-center align-top" rowspan="2" translate>
        ui.app.ccf.configurator.module-selection.module-selection-component-html.size-3-modules
      </th>
      <th class="text-center" colspan="4" translate>
        ui.app.ccf.configurator.module-selection.module-selection-component-html.side-connections
      </th>
    </tr>
    <tr>
      <th class="text-center">
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.right-left</div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.3-3</div>
      </th>
      <th class="text-center">
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.right</div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.3-0</div>
      </th>
      <th class="text-center">
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.left</div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-3</div>
      </th>
      <th class="text-center">
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.none</div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-0</div>
      </th>
    </tr>
  </thead>

  <tbody>
    <tr>
      <td>
        <div translate>
          ui.app.ccf.configurator.module-selection.module-selection-component-html.no-shaft-connection
        </div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-o</div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_3_3_o_o'])"
          [module]="modulesMap['module_3_3_3_o_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_3_3_o_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.3-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_3_0_o_o'])"
          [module]="modulesMap['module_3_3_0_o_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_3_0_o_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.3-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_0_3_o_o'])"
          [module]="modulesMap['module_3_0_3_o_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_0_3_o_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_0_0_o_o'])"
          [module]="modulesMap['module_3_0_0_o_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_0_0_o_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-o</span>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div translate>
          ui.app.ccf.configurator.module-selection.module-selection-component-html.bottom-front-shaft-connection
        </div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-o</div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_3_3_b_o'])"
          [module]="modulesMap['module_3_3_3_b_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_3_3_b_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.3-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_3_0_b_o'])"
          [module]="modulesMap['module_3_3_0_b_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_3_0_b_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.3-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_0_3_b_o'])"
          [module]="modulesMap['module_3_0_3_b_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_0_3_b_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-o</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_0_0_b_o'])"
          [module]="modulesMap['module_3_0_0_b_o']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_0_0_b_o']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-o</span>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div translate>
          ui.app.ccf.configurator.module-selection.module-selection-component-html.bottom-back-shaft-connection
        </div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-b</div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_3_3_o_b'])"
          [module]="modulesMap['module_3_3_3_o_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_3_3_o_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.3-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-b</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_3_0_o_b'])"
          [module]="modulesMap['module_3_3_0_o_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_3_0_o_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.3-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-b</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_0_3_o_b'])"
          [module]="modulesMap['module_3_0_3_o_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_0_3_o_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-b</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_0_0_o_b'])"
          [module]="modulesMap['module_3_0_0_o_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_0_0_o_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.o-b</span>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div translate>
          ui.app.ccf.configurator.module-selection.module-selection-component-html.bottom-front-and-back-shaft-connections
        </div>
        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-b</div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_3_3_b_b'])"
          [module]="modulesMap['module_3_3_3_b_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_3_3_b_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.3-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-b</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_3_0_b_b'])"
          [module]="modulesMap['module_3_3_0_b_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_3_0_b_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.3-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-b</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_0_3_b_b'])"
          [module]="modulesMap['module_3_0_3_b_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_0_3_b_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-b</span>
        </div>
      </td>
      <td class="text-center align-middle position-relative">
        <app-ccf-configurator-module-tile
          (click)="select(modulesMap['module_3_0_0_b_b'])"
          [module]="modulesMap['module_3_0_0_b_b']"
        ></app-ccf-configurator-module-tile>
        <div *ngIf="modulesMap['module_3_0_0_b_b']" class="position-absolute fixed-bottom">
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-3</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.0-0</span>
          /
          <span translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.b-b</span>
        </div>
      </td>
    </tr>
    <!--    <tr>-->
    <!--      <td>-->
    <!--        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.top-front-shaft-connection</div>-->
    <!--      </td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--      <td>-->
    <!--        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.top-back-shaft-connection</div>-->
    <!--      </td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--      <td>-->
    <!--        <div translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.top-front-and-back-shaft-connections</div>-->
    <!--      </td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--      <td class="text-center align-middle" translate>ui.app.ccf.configurator.module-selection.module-selection-component-html.module-not-possible</td>-->
    <!--    </tr>-->
  </tbody>
</table>
