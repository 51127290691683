import { Component, EventEmitter, Output } from '@angular/core';
import { CcfConfiguration, CcfPartEndModuleAdapter, CcfPartEndModuleType } from '@api';
import { BlankModuleSideComponent } from '../blank-module-side/blank-module-side.component';

@Component({
  selector: 'app-ccf-configurator-front-module-side',
  templateUrl: './front-module-side.component.html',
  styleUrls: ['./front-module-side.component.scss'],
})
export class FrontModuleSideComponent extends BlankModuleSideComponent {
  @Output() changedAdapter: EventEmitter<CcfConfiguration> = new EventEmitter<CcfConfiguration>();

  get CcfPartEndModuleType() {
    return CcfPartEndModuleType;
  }

  get CcfPartEndModuleAdapter() {
    return CcfPartEndModuleAdapter;
  }

  changeAdapter() {
    if (this.readonly) {
      return;
    }

    this.changedAdapter.emit(this.configuration);
  }
}
