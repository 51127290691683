<div class="custom-max-width">
  <div>
    <!--
    <h5>
      <span translate>ui.app.ccf.configurator.configurator-component-html.modules</span>
    </h5>
    -->
    <ng-container *ngIf="configuration && !isLoadingConfiguration && !isLoadingParts && !isLoadingConfigurationPresets">
      <div class="p-grid p-m-0">
        <div
          [ngClass]="{
            'p-col-6': !withoutSideConnectionAdapters && configuration?.side_connection_adapters,
            'p-col-12 p-pr-0': withoutSideConnectionAdapters || !configuration?.side_connection_adapters
          }"
          class="p-pl-0"
        >
          <div class="p-mb-3">
            <ng-container *ngIf="configuratorService.configurationPresets?.length">
              <p-button
                (onClick)="showConfigurationSelectionModal = true"
                [cTooltip]="
                  'ui.app.ccf.configurator.configurator-component-html.select-configuration-tooltip' | translate
                "
                [label]="'ui.app.ccf.configurator.configurator-component-html.select-a-configuration' | translate"
                icon="pi pi-plus"
                iconPos="left"
                styleClass="p-button-primary p-ml-1"
              ></p-button>
              <span class="p-ml-3" translate>
                ui.app.ccf.configurator.configurator-component-html.or-create-custom-configuration
              </span>
            </ng-container>
          </div>

          <div class="p-field">
            <label for="type" translate>ui.app.ccf.configurator.configurator-component-html.type</label>
            *
            <i
              *ngIf="'ui.app.ccf.configurator.configurator-component-html.type-tooltip' | translate"
              [cTooltip]="'ui.app.ccf.configurator.configurator-component-html.type-tooltip' | translate"
              class="pi pi-info-circle"
            ></i>
            <p-dropdown
              (ngModelChange)="configuration.type = $event; computeConfiguration()"
              [(ngModel)]="configuration.type"
              [disabled]="readonly"
              [filter]="true"
              [ngClass]="{
                'ng-invalid ng-dirty':
                  (validation?.errors && validation?.errors['type']) ||
                  ((configuration?.inside_insulation ||
                    configuration?.outside_insulation ||
                    configuration?.front_module_type ||
                    configuration?.front_module_adapter ||
                    configuration?.modules?.length ||
                    configuration?.end_module_type ||
                    configuration?.end_module_adapter) &&
                    showErrors &&
                    configuration?.errors &&
                    configuration?.errors['type'])
              }"
              [optionLabel]="descriptionService.name"
              [options]="types"
              [placeholder]="'ui.app.ccf.configurator.configurator-component-html.select' | translate"
              [resetFilterOnHide]="true"
              [showClear]="true"
              [styleClass]="'w-100' + (!configuration.type ? ' blank text-white blink' : '')"
              appendTo="body"
              inputId="type"
              name="type"
              optionValue="code"
            ></p-dropdown>
            <ng-container *ngIf="validation?.errors && validation?.errors['type']">
              <small *ngFor="let error of validation?.errors['type']" class="p-error">
                {{ error }}
              </small>
            </ng-container>
            <ng-container
              *ngIf="
                (configuration?.inside_insulation ||
                  configuration?.outside_insulation ||
                  configuration?.front_module_type ||
                  configuration?.front_module_adapter ||
                  configuration?.modules?.length ||
                  configuration?.end_module_type ||
                  configuration?.end_module_adapter) &&
                showErrors &&
                configuration?.errors &&
                configuration?.errors['type']
              "
            >
              <small *ngFor="let error of configuration?.errors['type']" class="p-error">
                {{ error }}
              </small>
            </ng-container>
            <ng-container *ngIf="validation?.warnings && validation?.warnings['type']">
              <small *ngFor="let warning of validation?.warnings['type']" class="c-warning">
                {{ warning }}
              </small>
            </ng-container>
            <ng-container *ngIf="configuration?.warnings && configuration?.warnings['type']">
              <small *ngFor="let warning of configuration?.errors['type']" class="c-warning">
                {{ warning }}
              </small>
            </ng-container>
          </div>

          <div class="p-field">
            <label for="inside_insulation" translate>
              ui.app.ccf.configurator.configurator-component-html.inside-insulation
            </label>
            <i
              *ngIf="'ui.app.ccf.configurator.configurator-component-html.inside-insulation-tooltip' | translate"
              [cTooltip]="'ui.app.ccf.configurator.configurator-component-html.inside-insulation-tooltip' | translate"
              class="pi pi-info-circle"
            ></i>
            <p-dropdown
              (ngModelChange)="configuration.inside_insulation = $event; computeConfiguration()"
              [(ngModel)]="configuration.inside_insulation"
              [disabled]="readonly"
              [filter]="true"
              [ngClass]="{
                'ng-invalid ng-dirty':
                  (validation?.errors && validation?.errors['inside_insulation']) ||
                  (showErrors && configuration?.errors && configuration?.errors[CcfPartCategory.ModuleInsideInsulation])
              }"
              [optionLabel]="descriptionService.name"
              [options]="booleans"
              [resetFilterOnHide]="true"
              [showClear]="true"
              appendTo="body"
              inputId="inside_insulation"
              name="inside_insulation"
              optionValue="code"
              placeholder="&nbsp;"
              styleClass="w-100"
            ></p-dropdown>
            <ng-container *ngIf="validation?.errors && validation?.errors['inside_insulation']">
              <small *ngFor="let error of validation?.errors['inside_insulation']" class="p-error">
                {{ error }}
              </small>
            </ng-container>
            <ng-container
              *ngIf="
                showErrors && configuration?.errors && configuration?.errors[CcfPartCategory.ModuleInsideInsulation]
              "
            >
              <small
                *ngFor="let error of configuration?.errors[CcfPartCategory.ModuleInsideInsulation]"
                class="p-error"
              >
                {{ error }}
              </small>
            </ng-container>
            <ng-container *ngIf="validation?.warnings && validation?.warnings['inside_insulation']">
              <small *ngFor="let warning of validation?.warnings['inside_insulation']" class="c-warning">
                {{ warning }}
              </small>
            </ng-container>
            <ng-container
              *ngIf="configuration?.warnings && configuration?.warnings[CcfPartCategory.ModuleInsideInsulation]"
            >
              <small
                *ngFor="let warning of configuration?.errors[CcfPartCategory.ModuleInsideInsulation]"
                class="c-warning"
              >
                {{ warning }}
              </small>
            </ng-container>
          </div>

          <div class="p-field">
            <label for="outside_insulation" translate>
              ui.app.ccf.configurator.configurator-component-html.outside-insulation
            </label>
            <i
              *ngIf="'ui.app.ccf.configurator.configurator-component-html.outside-insulation-tooltip' | translate"
              [cTooltip]="'ui.app.ccf.configurator.configurator-component-html.outside-insulation-tooltip' | translate"
              class="pi pi-info-circle"
            ></i>
            <p-dropdown
              (ngModelChange)="configuration.outside_insulation = $event; computeConfiguration()"
              [(ngModel)]="configuration.outside_insulation"
              [disabled]="readonly"
              [filter]="true"
              [ngClass]="{
                'ng-invalid ng-dirty':
                  (validation?.errors && validation?.errors['outside_insulation']) ||
                  (showErrors &&
                    configuration?.errors &&
                    configuration?.errors[CcfPartCategory.ModuleOutsideInsulation])
              }"
              [optionLabel]="descriptionService.name"
              [options]="booleans"
              [resetFilterOnHide]="true"
              [showClear]="true"
              appendTo="body"
              inputId="outside_insulation"
              name="outside_insulation"
              optionValue="code"
              placeholder="&nbsp;"
              styleClass="w-100"
            ></p-dropdown>
            <ng-container *ngIf="validation?.errors && validation?.errors['outside_insulation']">
              <small *ngFor="let error of validation?.errors['outside_insulation']" class="p-error">
                {{ error }}
              </small>
            </ng-container>
            <ng-container
              *ngIf="
                showErrors && configuration?.errors && configuration?.errors[CcfPartCategory.ModuleOutsideInsulation]
              "
            >
              <small
                *ngFor="let error of configuration?.errors[CcfPartCategory.ModuleOutsideInsulation]"
                class="p-error"
              >
                {{ error }}
              </small>
            </ng-container>
            <ng-container *ngIf="validation?.warnings && validation?.warnings['outside_insulation']">
              <small *ngFor="let warning of validation?.warnings['outside_insulation']" class="c-warning">
                {{ warning }}
              </small>
            </ng-container>
            <ng-container
              *ngIf="configuration?.warnings && configuration?.warnings[CcfPartCategory.ModuleOutsideInsulation]"
            >
              <small
                *ngFor="let warning of configuration?.errors[CcfPartCategory.ModuleOutsideInsulation]"
                class="c-warning"
              >
                {{ warning }}
              </small>
            </ng-container>
          </div>
        </div>

        <div
          *ngIf="!withoutSideConnectionAdapters && configuration?.side_connection_adapters"
          class="p-col-6 p-pr-0 p-d-flex p-ai-end"
        >
          <div class="w-full">
            <h5 class="p-mb-4" translate>ui.app.ccf.configurator.configurator-component-html.adapters</h5>
            <div *ngFor="let adapter of moduleSideConnectionAdapters" class="form-group">
              <div class="p-field">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <label [for]="adapter.code">{{ adapter.name }}</label>
                    <i *ngIf="adapter.tooltip" [cTooltip]="adapter.tooltip" class="pi pi-info-circle"></i>
                  </div>

                  <img
                    [attr.srcset]="partMedias[adapter.code]?.srcset"
                    [src]="partMedias[adapter.code] ? partMedias[adapter.code].url : 'assets/image-placeholder.png'"
                    class="adapter-image"
                  />
                </div>

                <c-inputNumber
                  (keydown)="resetErrorsAndWarnings('side_connection_adapters.' + adapter.code)"
                  (ngModelChange)="onModuleSideConnectionAdaptersChange(adapter.code, $event); computeConfiguration()"
                  [(ngModel)]="configuration?.side_connection_adapters[adapter.code]"
                  [disabled]="readonly"
                  [inputId]="adapter.code"
                  [locale]="formattingService.numberLocale"
                  [max]="12"
                  [min]="0"
                  [name]="adapter.code"
                  [ngClass]="{
                    'ng-invalid ng-dirty':
                      validation?.errors && validation?.errors['side_connection_adapters.' + adapter.code],
                    'w-100': true
                  }"
                  styleClass="w-100"
                ></c-inputNumber>

                <ng-container
                  *ngIf="validation?.errors && validation?.errors['side_connection_adapters.' + adapter.code]"
                >
                  <small
                    *ngFor="let error of validation?.errors['side_connection_adapters.' + adapter.code]"
                    class="p-error"
                  >
                    {{ error }}
                  </small>
                </ng-container>
                <ng-container
                  *ngIf="validation?.warnings && validation?.warnings['side_connection_adapters.' + adapter.code]"
                >
                  <small
                    *ngFor="let warning of validation?.warnings['side_connection_adapters.' + adapter.code]"
                    class="c-warning"
                  >
                    {{ warning }}
                  </small>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="d-screen-overflow-auto">
        <h5 [cTooltip]="'ui.app.ccf.configurator.configurator-component-html.side-view-tooltip' | translate" translate>
          ui.app.ccf.configurator.configurator-component-html.side-view
        </h5>
        <div #drawingSideView class="drawing-side-view">
          <app-ccf-configurator-front-module-side
            (changedAdapter)="showFrontModuleAdapterSelectionModal = true"
            (changedModule)="showFrontModuleSelectionModal = true"
            *ngIf="configuration?.front_module_type"
            [configuration]="configuration"
            [module]="frontModule"
            [readonly]="readonly"
          ></app-ccf-configurator-front-module-side>

          <app-ccf-configurator-blank-module-side
            (changedModule)="showFrontModuleSelectionModal = true"
            *ngIf="!configuration?.front_module_type"
            [configuration]="configuration"
            [readonly]="readonly"
            class="d-print-none"
          ></app-ccf-configurator-blank-module-side>

          <div *ngFor="let module of configuration?.modules">
            <app-ccf-configurator-size-2-module-side
              (changedModule)="showModuleSelectionModal = true; moduleToReplace = module"
              (deletedModule)="deleteModule(module).then()"
              (selected)="computeConfiguration()"
              *ngIf="module.size === CcfPartModuleSize.NUMBER_2"
              [configuration]="configuration"
              [module]="module"
              [readonly]="readonly"
            ></app-ccf-configurator-size-2-module-side>
            <app-ccf-configurator-size-3-module-side
              (changedModule)="showModuleSelectionModal = true; moduleToReplace = module"
              (deletedModule)="deleteModule(module).then()"
              (selected)="computeConfiguration()"
              *ngIf="module.size === CcfPartModuleSize.NUMBER_3"
              [configuration]="configuration"
              [module]="module"
              [readonly]="readonly"
            ></app-ccf-configurator-size-3-module-side>
          </div>

          <app-ccf-configurator-blank-module-side
            (changedModule)="showModuleSelectionModal = true; moduleToReplace = null"
            *ngIf="!configuration?.modules?.length"
            [configuration]="configuration"
            [readonly]="readonly"
            class="d-print-none"
          ></app-ccf-configurator-blank-module-side>
          <app-ccf-configurator-blank-module-side
            (changedModule)="showModuleSelectionModal = true; moduleToReplace = null"
            *ngIf="configuration?.modules?.length"
            [configuration]="configuration"
            [label]="'ui.app.ccf.configurator.configurator-component-html.add' | translate"
            [readonly]="readonly"
            [tooltip]="'ui.app.ccf.configurator.configurator-component-html.add-module-tooltip' | translate"
            class="d-print-none"
          ></app-ccf-configurator-blank-module-side>

          <app-ccf-configurator-blank-module-side
            (changedModule)="showEndModuleSelectionModal = true"
            *ngIf="!configuration?.end_module_type"
            [configuration]="configuration"
            [readonly]="readonly"
            class="d-print-none"
          ></app-ccf-configurator-blank-module-side>

          <app-ccf-configurator-end-module-side
            (changedAdapter)="showEndModuleAdapterSelectionModal = true"
            (changedModule)="showEndModuleSelectionModal = true"
            *ngIf="configuration?.end_module_type"
            [configuration]="configuration"
            [module]="endModule"
            [readonly]="readonly"
          ></app-ccf-configurator-end-module-side>
        </div>

        <hr />

        <h5 [cTooltip]="'ui.app.ccf.configurator.configurator-component-html.top-view-tooltip' | translate" translate>
          ui.app.ccf.configurator.configurator-component-html.top-view
        </h5>
        <div>
          <div translate>ui.app.ccf.configurator.configurator-component-html.right</div>
          <div #drawingTopView class="drawing-top-view">
            <app-ccf-configurator-front-module-top
              (changedAdapter)="showFrontModuleAdapterSelectionModal = true"
              (changedModule)="showFrontModuleSelectionModal = true"
              *ngIf="configuration?.front_module_type"
              [configuration]="configuration"
              [module]="frontModule"
              [readonly]="readonly"
            ></app-ccf-configurator-front-module-top>

            <app-ccf-configurator-blank-module-top
              (changedModule)="showFrontModuleSelectionModal = true"
              *ngIf="!configuration?.front_module_type"
              [configuration]="configuration"
              [readonly]="readonly"
              class="d-print-none"
            ></app-ccf-configurator-blank-module-top>

            <div *ngFor="let module of configuration?.modules">
              <app-ccf-configurator-size-2-module-top
                (changedModule)="showModuleSelectionModal = true; moduleToReplace = module"
                (deletedModule)="deleteModule(module).then()"
                *ngIf="module.size === CcfPartModuleSize.NUMBER_2"
                [configuration]="configuration"
                [module]="module"
                [readonly]="readonly"
              ></app-ccf-configurator-size-2-module-top>
              <app-ccf-configurator-size-3-module-top
                (changedModule)="showModuleSelectionModal = true; moduleToReplace = module"
                (deletedModule)="deleteModule(module).then()"
                *ngIf="module.size === CcfPartModuleSize.NUMBER_3"
                [configuration]="configuration"
                [module]="module"
                [readonly]="readonly"
              ></app-ccf-configurator-size-3-module-top>
            </div>

            <app-ccf-configurator-blank-module-top
              (changedModule)="showModuleSelectionModal = true; moduleToReplace = null"
              *ngIf="!configuration?.modules?.length"
              [configuration]="configuration"
              [readonly]="readonly"
              class="d-print-none"
            ></app-ccf-configurator-blank-module-top>
            <app-ccf-configurator-blank-module-top
              (changedModule)="showModuleSelectionModal = true; moduleToReplace = null"
              *ngIf="configuration?.modules?.length"
              [configuration]="configuration"
              [label]="'ui.app.ccf.configurator.configurator-component-html.add' | translate"
              [readonly]="readonly"
              [tooltip]="'ui.app.ccf.configurator.configurator-component-html.add-module-tooltip' | translate"
              class="d-print-none"
            ></app-ccf-configurator-blank-module-top>

            <app-ccf-configurator-blank-module-top
              (changedModule)="showEndModuleSelectionModal = true"
              *ngIf="!configuration?.end_module_type"
              [configuration]="configuration"
              [readonly]="readonly"
              class="d-print-none"
            ></app-ccf-configurator-blank-module-top>

            <app-ccf-configurator-end-module-top
              (changedAdapter)="showEndModuleAdapterSelectionModal = true"
              (changedModule)="showEndModuleSelectionModal = true"
              *ngIf="configuration?.end_module_type"
              [configuration]="configuration"
              [module]="endModule"
              [readonly]="readonly"
            ></app-ccf-configurator-end-module-top>

            <div [cTooltip]="'ui.app.ccf.configurator.configurator-component-html.eye-tooltip' | translate">
              <svg
                class="arrow-svg"
                height="170.4918032786885"
                id="svg"
                version="1.1"
                viewBox="0, 0, 400,170.4918032786885"
                width="400"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="svgg">
                  <path
                    d="M124.590 20.381 C 108.661 29.698,77.186 47.928,54.645 60.894 C 32.104 73.859,
                      13.508 84.590,13.321 84.741 C 13.135 84.892,22.706 90.600,34.592 97.425 C 46.477
                      104.251,78.352 122.689,105.423 138.398 L 154.645 166.961 154.938 141.404 L 155.231
                      115.847 270.512 115.847 L 385.792 115.847 385.792 85.246 L 385.792 54.645 270.492
                      54.645 L 155.191 54.645 155.191 28.962 C 155.191 14.836,154.822 3.316,154.372
                      3.361 C 153.921 3.406,140.519 11.065,124.590 20.381 "
                    fill="#000000"
                    fill-rule="evenodd"
                    id="path0"
                    stroke="none"
                  />
                </g>
              </svg>
              <svg
                class="eye-svg"
                height="456.795px"
                id="Capa_1"
                style="enable-background: new 0 0 456.795 456.795"
                version="1.1"
                viewBox="0 0 456.795 456.795"
                width="456.795px"
                x="0px"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                y="0px"
              >
                <g>
                  <g>
                    <path
                      d="M448.947,218.475c-0.922-1.168-23.055-28.933-61-56.81c-50.705-37.253-105.877-56.944-159.551-56.944
                    c-53.672,0-108.844,19.691-159.551,56.944c-37.944,27.876-60.077,55.642-61,56.81L0,228.397l7.846,9.923
                    c0.923,1.168,23.056,28.934,61,56.811c50.707,37.252,105.879,56.943,159.551,56.943c53.673,0,108.845-19.691,159.55-56.943
                    c37.945-27.877,60.078-55.643,61-56.811l7.848-9.923L448.947,218.475z M228.396,315.039c-47.774,0-86.642-38.867-86.642-86.642
                    c0-7.485,0.954-14.751,2.747-21.684l-19.781-3.329c-1.938,8.025-2.966,16.401-2.966,25.013c0,30.86,13.182,58.696,34.204,78.187
                    c-27.061-9.996-50.072-24.023-67.439-36.709c-21.516-15.715-37.641-31.609-46.834-41.478c9.197-9.872,25.32-25.764,46.834-41.478
                    c17.367-12.686,40.379-26.713,67.439-36.71l13.27,14.958c15.498-14.512,36.312-23.412,59.168-23.412
                    c47.774,0,86.641,38.867,86.641,86.642C315.037,276.172,276.17,315.039,228.396,315.039z M368.273,269.875
                    c-17.369,12.686-40.379,26.713-67.439,36.709c21.021-19.49,34.203-47.326,34.203-78.188s-13.182-58.697-34.203-78.188
                    c27.061,9.997,50.07,24.024,67.439,36.71c21.516,15.715,37.641,31.609,46.834,41.477
                    C405.91,238.269,389.787,254.162,368.273,269.875z"
                    />
                    <path
                      d="M173.261,211.555c-1.626,5.329-2.507,10.982-2.507,16.843c0,31.834,25.807,57.642,57.642,57.642
                    c31.834,0,57.641-25.807,57.641-57.642s-25.807-57.642-57.641-57.642c-15.506,0-29.571,6.134-39.932,16.094l28.432,32.048
                    L173.261,211.555z"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>

          <div class="dimensions-summary">
            <span *ngIf="configuration.length">
              {{ 'ui.app.ccf.configurator.configurator-component-html.total-length' | translate }}:
              {{ configuration.length }}.
            </span>
            {{ 'ui.app.ccf.configurator.configurator-component-html.all-measurements-are-in-mm' | translate }}
          </div>

          <div translate>ui.app.ccf.configurator.configurator-component-html.left</div>
        </div>

        <div *ngIf="configuration?.article_number || configuration?.code" class="d-none d-print-block">
          <hr />

          <h5 translate>ui.app.ccf.configurator.configurator-component-html.sap-info</h5>
          <div>{{ configuration?.article_number }}</div>
          <div>{{ configuration?.code }}</div>
        </div>
      </div>

      <div
        *ngIf="
          !readonly &&
          (configuration?.front_module_type ||
            configuration?.front_module_adapter ||
            configuration?.modules?.length ||
            configuration?.end_module_type ||
            configuration?.end_module_adapter)
        "
        class="d-print-none"
      >
        <hr />

        <ng-container
          *ngIf="
            configuration?.front_module_type ||
            configuration?.front_module_adapter ||
            configuration?.modules?.length ||
            configuration?.end_module_type ||
            configuration?.end_module_adapter
          "
        >
          <span *ngIf="configuratorService.configurationPresets?.length" translate>
            ui.app.ccf.configurator.configurator-component-html.or
          </span>
          <p-button
            (onClick)="clearConfiguration().then()"
            [cTooltip]="'ui.app.ccf.configurator.configurator-component-html.clear-configuration-tooltip' | translate"
            [label]="'ui.app.ccf.configurator.configurator-component-html.clear-the-configuration' | translate"
            icon="pi pi-times"
            iconPos="left"
            styleClass="p-button-primary p-ml-1"
          ></p-button>
        </ng-container>
      </div>

      <ng-container
        *ngIf="
          configuration?.front_module_type ||
          configuration?.front_module_adapter ||
          configuration?.modules?.length ||
          configuration?.end_module_type ||
          configuration?.end_module_adapter
        "
      >
        <div
          *ngIf="showErrors && configuration?.errors"
          [cTooltip]="'ui.app.ccf.configurator.configurator-component-html.errors-tooltip' | translate"
          class="text-danger"
        >
          <hr />

          <ng-container *ngFor="let error of configuration?.errors | keyvalue">
            <span *ngFor="let message of error.value" class="p-mr-1">{{ message }}</span>
          </ng-container>
        </div>

        <div
          *ngIf="configuration?.warnings"
          [cTooltip]="'ui.app.ccf.configurator.configurator-component-html.warnings-tooltip' | translate"
          class="text-warning"
        >
          <hr />

          <ng-container *ngFor="let warning of configuration?.warnings | keyvalue">
            <span *ngFor="let message of warning.value" class="p-mr-1">{{ message }}</span>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <p-dialog
      [(visible)]="showFrontModuleSelectionModal"
      [closeOnEscape]="true"
      [dismissableMask]="false"
      [modal]="true"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="header">
        <h4 class="text-ellipsis" translate>
          ui.app.ccf.configurator.configurator-component-html.select-a-front-module
        </h4>
      </ng-template>

      <ng-template pTemplate="content">
        <app-ccf-configurator-module-selection
          (selected)="applyFrontModuleSelection($event); showFrontModuleSelectionModal = null"
          [modules]="frontModules"
        ></app-ccf-configurator-module-selection>
      </ng-template>

      <ng-template pTemplate="footer">
        <p-button
          (onClick)="showFrontModuleSelectionModal = null"
          [label]="'ui.app.ccf.configurator.configurator-component-html.cancel' | translate"
          icon="pi pi-times"
          styleClass="p-button-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>

    <p-dialog
      [(visible)]="showFrontModuleAdapterSelectionModal"
      [closeOnEscape]="true"
      [dismissableMask]="false"
      [modal]="true"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="header">
        <h4 class="text-ellipsis" translate>
          ui.app.ccf.configurator.configurator-component-html.select-a-front-module-adapter
        </h4>
      </ng-template>

      <ng-template pTemplate="content">
        <app-ccf-configurator-module-selection
          (selected)="applyFrontModuleAdapterSelection($event); showFrontModuleAdapterSelectionModal = null"
          [modules]="frontModuleAdapters"
        ></app-ccf-configurator-module-selection>
      </ng-template>

      <ng-template pTemplate="footer">
        <p-button
          (onClick)="showFrontModuleAdapterSelectionModal = null"
          [label]="'ui.app.ccf.configurator.configurator-component-html.cancel' | translate"
          icon="pi pi-times"
          styleClass="p-button-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>

    <p-dialog
      [(visible)]="showEndModuleSelectionModal"
      [closeOnEscape]="true"
      [dismissableMask]="false"
      [modal]="true"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="header">
        <h4 class="text-ellipsis" translate>
          ui.app.ccf.configurator.configurator-component-html.select-an-end-module
        </h4>
      </ng-template>

      <ng-template pTemplate="content">
        <app-ccf-configurator-module-selection
          (selected)="applyEndModuleSelection($event); showEndModuleSelectionModal = null"
          [modules]="endModules"
        ></app-ccf-configurator-module-selection>
      </ng-template>

      <ng-template pTemplate="footer">
        <p-button
          (onClick)="showEndModuleSelectionModal = null"
          [label]="'ui.app.ccf.configurator.configurator-component-html.cancel' | translate"
          icon="pi pi-times"
          styleClass="p-button-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>

    <p-dialog
      [(visible)]="showEndModuleAdapterSelectionModal"
      [closeOnEscape]="true"
      [dismissableMask]="false"
      [modal]="true"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="header">
        <h4 class="text-ellipsis" translate>
          ui.app.ccf.configurator.configurator-component-html.select-an-end-module-adapter
        </h4>
      </ng-template>

      <ng-template pTemplate="content">
        <app-ccf-configurator-module-selection
          (selected)="applyEndModuleAdapterSelection($event); showEndModuleAdapterSelectionModal = null"
          [modules]="endModuleAdapters"
        ></app-ccf-configurator-module-selection>
      </ng-template>

      <ng-template pTemplate="footer">
        <p-button
          (onClick)="showEndModuleAdapterSelectionModal = null"
          [label]="'ui.app.ccf.configurator.configurator-component-html.cancel' | translate"
          icon="pi pi-times"
          styleClass="p-button-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>

    <p-dialog
      [(visible)]="showModuleSelectionModal"
      [closeOnEscape]="true"
      [dismissableMask]="false"
      [modal]="true"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="header">
        <h4 class="text-ellipsis" translate>ui.app.ccf.configurator.configurator-component-html.select-a-module</h4>
      </ng-template>

      <ng-template pTemplate="content">
        <app-ccf-configurator-module-selection
          (selected)="applyModuleSelection($event); moduleToReplace = null; showModuleSelectionModal = null"
          [mapped]="true"
          [modules]="modules"
        ></app-ccf-configurator-module-selection>
      </ng-template>

      <ng-template pTemplate="footer">
        <p-button
          (onClick)="showModuleSelectionModal = null"
          [label]="'ui.app.ccf.configurator.configurator-component-html.cancel' | translate"
          icon="pi pi-times"
          styleClass="p-button-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>

    <p-dialog
      [(visible)]="showConfigurationSelectionModal"
      [closeOnEscape]="true"
      [dismissableMask]="false"
      [modal]="true"
      [style]="{ width: '50vw' }"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="header">
        <h4 class="text-ellipsis" translate>
          ui.app.ccf.configurator.configurator-component-html.select-a-configuration
        </h4>
      </ng-template>

      <ng-template pTemplate="content">
        <app-ccf-configurator-module-selection
          (selected)="applyConfigurationSelection($event); showConfigurationSelectionModal = null"
          [modules]="configuratorService.configurationPresets"
          [search]="true"
        ></app-ccf-configurator-module-selection>
      </ng-template>

      <ng-template pTemplate="footer">
        <p-button
          (onClick)="showConfigurationSelectionModal = null"
          [label]="'ui.app.ccf.configurator.configurator-component-html.cancel' | translate"
          icon="pi pi-times"
          styleClass="p-button-secondary"
        ></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>

<app-loader
  *ngIf="!configuration || isLoadingConfiguration || isLoadingParts || isLoadingConfigurationPresets"
></app-loader>
