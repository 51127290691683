/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjBuildingCalculationMethod = 'din' | 'nl' | 'be' | 'fr' | 'ee' | 'nrb' | 'lcb' | 'ch_comp' | 'ch_casc';

export const PrjBuildingCalculationMethod = {
    Din: 'din' as PrjBuildingCalculationMethod,
    Nl: 'nl' as PrjBuildingCalculationMethod,
    Be: 'be' as PrjBuildingCalculationMethod,
    Fr: 'fr' as PrjBuildingCalculationMethod,
    Ee: 'ee' as PrjBuildingCalculationMethod,
    Nrb: 'nrb' as PrjBuildingCalculationMethod,
    Lcb: 'lcb' as PrjBuildingCalculationMethod,
    ChComp: 'ch_comp' as PrjBuildingCalculationMethod,
    ChCasc: 'ch_casc' as PrjBuildingCalculationMethod
};

