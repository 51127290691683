/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SettingType = 'mail_from_address' | 'mail_from_name' | 'default_locale' | 'available_locales' | 'default_country' | 'available_countries' | 'default_user_registration_data' | 'authorized_emails' | 'own_projects' | 'project_lock_column' | 'default_project_type' | 'available_project_types' | 'sap_import_folder' | 'sap_purge_deleted' | 'sap_price_date_backward_days' | 'unit_conversion_airflow' | 'unit_conversion_temperature' | 'learn_link' | 'terms_link' | 'bim_link' | 'default_vtweg' | 'default_spart' | 'default_crm_sales_org' | 'available_crm_sales_org' | 'cpd_email' | 'purchase_email' | 'use_opportunity_for_project_name' | 'hidden_stakeholder_fields' | 'crm_locale_conversions' | 'crm_stakeholder_types' | 'number_format' | 'date_format' | 'long_date_format' | 'vat' | 'default_currency' | 'available_currencies' | 'article_transfers' | 'integrity_reports' | 'article_statuses' | 'article_material_types' | 'article_hierarchies' | 'article_exceptions' | 'price_tables' | 'pdf_document_name' | 'gaeb_document_name' | 'gaeb_pos_number' | 'document_email_cc' | 'document_email_bcc' | 'price_date_expiration_days' | 'prefill_request_date' | 'available_calculation_methods' | 'planning_basics' | 'planning_room_colors' | 'planning_toggle_defaults' | 'planning_default_manual_airflows' | 'max_floor_overflow' | 'outlet_flow' | 'ccf_manifolds' | 'metal_manifolds' | 'materials_toggle_defaults' | 'materials_sets' | 'decentralized_max_flow' | 'decentralized_max_tube_flow' | 'on_hold_category_code' | 'price_group_discounts' | 'project_discount' | 'disabled_sap_pricing' | 'default_print_packing_units' | 'default_sort_materials' | 'default_sort_rooms' | 'include_surcharge';

export const SettingType = {
    MailFromAddress: 'mail_from_address' as SettingType,
    MailFromName: 'mail_from_name' as SettingType,
    DefaultLocale: 'default_locale' as SettingType,
    AvailableLocales: 'available_locales' as SettingType,
    DefaultCountry: 'default_country' as SettingType,
    AvailableCountries: 'available_countries' as SettingType,
    DefaultUserRegistrationData: 'default_user_registration_data' as SettingType,
    AuthorizedEmails: 'authorized_emails' as SettingType,
    OwnProjects: 'own_projects' as SettingType,
    ProjectLockColumn: 'project_lock_column' as SettingType,
    DefaultProjectType: 'default_project_type' as SettingType,
    AvailableProjectTypes: 'available_project_types' as SettingType,
    SapImportFolder: 'sap_import_folder' as SettingType,
    SapPurgeDeleted: 'sap_purge_deleted' as SettingType,
    SapPriceDateBackwardDays: 'sap_price_date_backward_days' as SettingType,
    UnitConversionAirflow: 'unit_conversion_airflow' as SettingType,
    UnitConversionTemperature: 'unit_conversion_temperature' as SettingType,
    LearnLink: 'learn_link' as SettingType,
    TermsLink: 'terms_link' as SettingType,
    BimLink: 'bim_link' as SettingType,
    DefaultVtweg: 'default_vtweg' as SettingType,
    DefaultSpart: 'default_spart' as SettingType,
    DefaultCrmSalesOrg: 'default_crm_sales_org' as SettingType,
    AvailableCrmSalesOrg: 'available_crm_sales_org' as SettingType,
    CpdEmail: 'cpd_email' as SettingType,
    PurchaseEmail: 'purchase_email' as SettingType,
    UseOpportunityForProjectName: 'use_opportunity_for_project_name' as SettingType,
    HiddenStakeholderFields: 'hidden_stakeholder_fields' as SettingType,
    CrmLocaleConversions: 'crm_locale_conversions' as SettingType,
    CrmStakeholderTypes: 'crm_stakeholder_types' as SettingType,
    NumberFormat: 'number_format' as SettingType,
    DateFormat: 'date_format' as SettingType,
    LongDateFormat: 'long_date_format' as SettingType,
    Vat: 'vat' as SettingType,
    DefaultCurrency: 'default_currency' as SettingType,
    AvailableCurrencies: 'available_currencies' as SettingType,
    ArticleTransfers: 'article_transfers' as SettingType,
    IntegrityReports: 'integrity_reports' as SettingType,
    ArticleStatuses: 'article_statuses' as SettingType,
    ArticleMaterialTypes: 'article_material_types' as SettingType,
    ArticleHierarchies: 'article_hierarchies' as SettingType,
    ArticleExceptions: 'article_exceptions' as SettingType,
    PriceTables: 'price_tables' as SettingType,
    PdfDocumentName: 'pdf_document_name' as SettingType,
    GaebDocumentName: 'gaeb_document_name' as SettingType,
    GaebPosNumber: 'gaeb_pos_number' as SettingType,
    DocumentEmailCc: 'document_email_cc' as SettingType,
    DocumentEmailBcc: 'document_email_bcc' as SettingType,
    PriceDateExpirationDays: 'price_date_expiration_days' as SettingType,
    PrefillRequestDate: 'prefill_request_date' as SettingType,
    AvailableCalculationMethods: 'available_calculation_methods' as SettingType,
    PlanningBasics: 'planning_basics' as SettingType,
    PlanningRoomColors: 'planning_room_colors' as SettingType,
    PlanningToggleDefaults: 'planning_toggle_defaults' as SettingType,
    PlanningDefaultManualAirflows: 'planning_default_manual_airflows' as SettingType,
    MaxFloorOverflow: 'max_floor_overflow' as SettingType,
    OutletFlow: 'outlet_flow' as SettingType,
    CcfManifolds: 'ccf_manifolds' as SettingType,
    MetalManifolds: 'metal_manifolds' as SettingType,
    MaterialsToggleDefaults: 'materials_toggle_defaults' as SettingType,
    MaterialsSets: 'materials_sets' as SettingType,
    DecentralizedMaxFlow: 'decentralized_max_flow' as SettingType,
    DecentralizedMaxTubeFlow: 'decentralized_max_tube_flow' as SettingType,
    OnHoldCategoryCode: 'on_hold_category_code' as SettingType,
    PriceGroupDiscounts: 'price_group_discounts' as SettingType,
    ProjectDiscount: 'project_discount' as SettingType,
    DisabledSapPricing: 'disabled_sap_pricing' as SettingType,
    DefaultPrintPackingUnits: 'default_print_packing_units' as SettingType,
    DefaultSortMaterials: 'default_sort_materials' as SettingType,
    DefaultSortRooms: 'default_sort_rooms' as SettingType,
    IncludeSurcharge: 'include_surcharge' as SettingType
};

