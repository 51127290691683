import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AuthService,
  Country,
  ErrorValidationResponse,
  Gender,
  Locale,
  Mbu,
  MbusService,
  PermissionName,
  PrjProjectType,
  Role,
  RolesService,
  SettingType,
  User,
  UserGroup,
  UserGroupsService,
  UserInput,
  UserMbu,
  UserRole,
  UsersService,
  UserUserGroup,
} from '@api';
import {
  CodeNameEntity,
  ConfigService,
  CredentialsService,
  DescriptionService,
  FormattingService,
  TitleService,
  UnloaderService,
} from '@core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@app/auth/authentication.service';
import { ConfirmationService, MenuItem } from 'primeng/api';

export interface GenderOption {
  code: Gender;
  name: string;
}

@UntilDestroy()
@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userId: number;
  @Input() duplicateUserId: number;
  @Input() forProfile = false;

  @Output() saved: EventEmitter<User> = new EventEmitter<User>();
  @Output() cancelled: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('input') input: ElementRef;

  isLoading: number;
  isSaving: number;
  canChangeUserEmail: boolean;
  user: User;
  canAccessAllMbus: boolean;
  userInput: UserInput;
  originalUserInput: string;
  profilePictureSrc: string;
  profilePictureSrcSet: string;
  profilePictureFile: File;
  profilePictureWidth: number;
  profilePictureHeight: number;
  validation: ErrorValidationResponse;
  localesAll: Locale[];
  locales: Locale[];
  countries: Country[];
  countriesAll: Country[];
  usersQuery$: Subject<string>;
  areaManagerSuggestions: User[];
  users: User[];
  usersCount: number;
  managerUserSelection: User;
  areaManagerUserSelection: User;
  mbus: Mbu[];
  mbuSelection: Mbu;
  customerNumber: string;
  defaultUserMbuSelectionCode: string;
  currentUserMbu: UserMbu;
  roles: Role[];
  roleSelection: Role;
  currentUserRole: UserRole;
  userGroups: UserGroup[];
  userGroupSelection: UserGroup;
  currentUserUserGroup: UserUserGroup;
  showRelationsModal: boolean;
  relationMenuItems: MenuItem[];
  projectTypes: CodeNameEntity[];
  availableCrmSalesOrgsAll: string[];
  availableCrmSalesOrgs: string[];
  genders: GenderOption[];

  checkChanges: Function;

  constructor(
    public credentialsService: CredentialsService,
    public apiUsersService: UsersService,
    public apiAuthService: AuthService,
    public apiMbusService: MbusService,
    public apiRolesService: RolesService,
    public configService: ConfigService,
    public unloaderService: UnloaderService,
    public translateService: TranslateService,
    public authenticationService: AuthenticationService,
    public apiUserGroupsService: UserGroupsService,
    public confirmationService: ConfirmationService,
    public titleService: TitleService,
    public descriptionService: DescriptionService,
    public formattingService: FormattingService,
  ) {
    credentialsService.credentials$.pipe(untilDestroyed(this)).subscribe(() => {
      this.canChangeUserEmail = credentialsService.can(PermissionName.ChangeUserEmail);

      // this.canAccessAllMbus depends on the selected user not the logged in one
      // this.canAccessAllMbus = this.credentialsService.can(PermissionName.AccessAllMbus);
    });

    this.reset();

    this.localesAll = [];
    this.configService.translationsConfig$.pipe(untilDestroyed(this)).subscribe((config) => {
      this.localesAll = config.locales;

      this.processUser();
    });

    this.countriesAll = [];
    this.configService.translationsConfig$.pipe(untilDestroyed(this)).subscribe((config) => {
      this.countriesAll = config.countries;

      this.processUser();
    });

    this.availableCrmSalesOrgs = this.availableCrmSalesOrgsAll = [];
    this.configService.settingsConfig$.pipe(untilDestroyed(this)).subscribe((settings) => {
      this.availableCrmSalesOrgs = this.availableCrmSalesOrgsAll = (settings[SettingType.AvailableCrmSalesOrg] ||
        []) as string[];
      this.availableCrmSalesOrgs.sort();
    });

    this.checkChanges = () => {
      return (
        (this.originalUserInput && this.originalUserInput !== JSON.stringify(this.userInput)) || this.profilePictureFile
      );
    };
    this.unloaderService.register(this.checkChanges);
  }

  reset() {
    this.isLoading = 0;
    this.isSaving = 0;
    this.user = null;
    this.canAccessAllMbus = false; // this.canAccessAllMbus depends on the selected user not the logged in one
    this.userInput = {
      code: '',
      manager_user_id: null,
      email: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      gender: null,
      phone: '',
      mobile: '',
      fax: '',
      company_name: '',
      company_function: '',
      street: '',
      zip_code: '',
      city: '',
      country: '',
      url: '',
      vat_type: '',
      training: null,
      contact: null,
      newsletter: null,
      extra_info: '',
      remarks: '',
      authorization: '',
      locale: '',
      blocked_project_types: [],
      crm_sales_org: '',
      area_number: '',
      employee_number: '',
      authorized: null,
      user_mbus: [],
      user_roles: [],
      user_user_groups: [],
    };
    this.originalUserInput = JSON.stringify(this.userInput);
    if (this.input && this.input.nativeElement) {
      this.input.nativeElement.value = '';
    }
    this.profilePictureSrc = '';
    this.profilePictureSrcSet = '';
    this.profilePictureFile = null;
    this.profilePictureWidth = null;
    this.profilePictureHeight = null;
    this.validation = {};
    this.locales = [];
    this.countries = [];
    this.usersQuery$ = new Subject<string>();
    this.users = null;
    this.usersCount = null;
    this.managerUserSelection = null;
    this.areaManagerUserSelection = null;
    this.mbuSelection = null;
    this.customerNumber = null;
    this.defaultUserMbuSelectionCode = null;
    this.currentUserMbu = null;
    this.roleSelection = null;
    this.currentUserRole = null;
    this.userGroupSelection = null;
    this.currentUserUserGroup = null;
    this.showRelationsModal = false;

    (async () => {
      this.projectTypes = [
        { code: PrjProjectType.Plan, name: await this.translateService.get('ui.project_type.plan').toPromise() },
        { code: PrjProjectType.Quote, name: await this.translateService.get('ui.project_type.quote').toPromise() },
        {
          code: PrjProjectType.QuotePlus,
          name: await this.translateService.get('ui.project_type.quote_plus').toPromise(),
        },
      ];
      // this.projectTypes.sort((opt1, opt2) => opt1.code.localeCompare(opt2.code)); // this is pre-sorted

      this.genders = [
        { code: Gender.M, name: await this.translateService.get('ui.gender.m').toPromise() },
        { code: Gender.F, name: await this.translateService.get('ui.gender.f').toPromise() },
      ];
      // this.genders.sort((opt1, opt2) => opt1.code.localeCompare(opt2.code)); // this is pre-sorted
    })().then();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.forProfile || changes.userId || changes.duplicateUserId) {
      this.reset();
      this.ngOnInit().then();
    }
  }

  async ngOnInit() {
    // this.userId = 0 will pass for user creation
    if (
      !this.forProfile &&
      [null, undefined].indexOf(this.userId) !== -1 &&
      [null, undefined].indexOf(this.duplicateUserId) !== -1
    ) {
      // Component is hidden in stand by
      return;
    }

    this.titleService.push();

    if (this.forProfile) {
      this.titleService.replace(
        await this.translateService.get('ui.app.user-form.user-form-component-ts.profile').toPromise(),
      );

      this.isLoading++;
      this.apiAuthService
        .authGetMe()
        .pipe(untilDestroyed(this))
        .subscribe(
          (res) => {
            this.processUser(res.data);

            this.isLoading--;
          },
          () => this.isLoading--,
        );
    } else {
      if (!this.mbus) {
        // Only load once
        this.mbus = [];
        this.isLoading++;
        // Allow all the MBUs to allow forwarding
        this.apiMbusService
          .mbusList(null, null, null, 0, 'code', 'asc')
          .pipe(untilDestroyed(this))
          .subscribe(
            (res) => {
              this.mbus = res.data || [];

              this.processUser();

              this.isLoading--;
            },
            () => this.isLoading--,
          );
      }

      if (!this.roles) {
        // Only load once
        this.roles = [];
        this.isLoading++;
        this.apiRolesService
          .rolesList(null, null, null, 0, 'name', 'asc')
          .pipe(untilDestroyed(this))
          .subscribe(
            (res) => {
              this.roles = res.data || [];

              this.processUser();

              this.isLoading--;
            },
            () => this.isLoading--,
          );
      }

      this.usersQuery$
        .pipe(
          untilDestroyed(this),
          switchMap((query) =>
            this.apiUsersService.usersList(
              query,
              null,
              1,
              10,
              'inverted_name',
              'asc',
              JSON.stringify(['id', 'inverted_name', 'company_name', 'profile_picture_url', 'profile_picture_srcset']),
              null,
              null,
              null,
              null,
              null,
              null,
              true, // exclude_text
            ),
          ),
        )
        .subscribe((res) => {
          this.users = res.data;
          this.usersCount = res.meta ? res.meta.total : null;
        });

      if (!this.areaManagerSuggestions) {
        // Only load once
        this.areaManagerSuggestions = [];
        this.isLoading++;
        this.apiUsersService
          .usersList(
            null,
            null,
            null,
            0,
            'area_number',
            'asc',
            JSON.stringify(['id', 'inverted_name', 'profile_picture_url', 'profile_picture_srcset', 'area_number']),
            null,
            null,
            null,
            null,
            true,
            null,
            true, // exclude_text
          )
          .pipe(untilDestroyed(this))
          .subscribe(
            (res) => {
              this.areaManagerSuggestions = res.data;

              this.isLoading--;
            },
            () => this.isLoading--,
          );
      }

      if (!this.userGroups) {
        // Only load once
        this.userGroups = [];
        this.isLoading++;
        this.apiUserGroupsService
          .userGroupsList(null, null, null, 0, 'name', 'asc', JSON.stringify(['id', 'name']))
          .pipe(untilDestroyed(this))
          .subscribe(
            (res) => {
              this.userGroups = res.data;

              this.isLoading--;
            },
            () => this.isLoading--,
          );
      }

      if (this.userId || this.duplicateUserId) {
        this.isLoading++;
        this.apiUsersService
          .userDetails(
            this.userId || this.duplicateUserId,
            null,
            JSON.stringify([
              'manager_user',
              'user_mbus',
              'user_mbus.mbu',
              'user_mbus.area_manager_user',
              'all_roles',
              'user_user_groups.mbu',
              'user_user_groups.user_group',
              'comment',
            ]),
          )
          .pipe(untilDestroyed(this))
          .subscribe(
            async (res) => {
              if (!this.userId) {
                this.titleService.replace(
                  await this.translateService.get('ui.app.user-form.user-form-component-ts.new-user').toPromise(),
                );

                res.data.id = null;
                res.data.code = null;
                res.data.username = null;
                res.data.profile_picture_url = null;
                res.data.profile_picture_srcset = null;
              } else {
                this.titleService.replace(res.data.name);
              }

              this.processUser(res.data);

              this.isLoading--;
            },
            () => this.isLoading--,
          );
      } else {
        this.titleService.replace(
          await this.translateService.get('ui.app.user-form.user-form-component-ts.new-user').toPromise(),
        );

        this.processUser();
      }
    }
  }

  processUser(user?: User) {
    if (user) {
      this.user = user;
      Object.keys(this.userInput).forEach((key) => {
        let value = this.user[key];
        if ([null, undefined].indexOf(value) !== -1) {
          switch (typeof this.userInput[key]) {
            case 'boolean':
              value = false;
              break;
            case 'string':
              value = '';
              break;
            case 'number':
              value = 0;
              break;
          }
        }
        this.userInput[key] = value;
      });

      this.profilePictureSrc = this.user.profile_picture_url || '';
      this.profilePictureSrcSet = this.user.profile_picture_srcset || '';

      this.managerUserSelection = user.manager_user;

      this.canAccessAllMbus = this.credentialsService.can(PermissionName.AccessAllMbus, user);

      if (user.blocked_project_types) {
        this.userInput.blocked_project_types = user.blocked_project_types.split(',') as PrjProjectType[];
      }

      const defaultUserMbuSelection = this.userInput.user_mbus
        ? this.userInput.user_mbus.find((userMbu) => userMbu.is_default)
        : null;
      this.defaultUserMbuSelectionCode = defaultUserMbuSelection ? defaultUserMbuSelection.mbu_code : null;

      this.userInput.user_roles = user.all_roles
        ? user.all_roles.map((role) => {
            const userRole: UserRole = { role_id: role.id };
            if (role.pivot) {
              userRole.mbu_code = role.pivot.mbu_code;
            }
            return userRole;
          })
        : [];

      this.userInput.comment_text = user.comment ? user.comment.text : null;
    }

    this.availableCrmSalesOrgs = [...(this.availableCrmSalesOrgsAll || [])];
    if (this.userInput.crm_sales_org && this.availableCrmSalesOrgs.indexOf(this.userInput.crm_sales_org) === -1) {
      this.availableCrmSalesOrgs.push(this.userInput.crm_sales_org);
    }
    this.availableCrmSalesOrgs.sort();

    if (
      !this.userId &&
      (!this.userInput.user_mbus || !this.userInput.user_mbus.length) &&
      this.mbus &&
      this.mbus.length
    ) {
      let mbu = this.mbus.find((mbu) => mbu.is_current);
      if (!mbu) {
        mbu = this.mbus[0];
      }
      this.userInput.user_mbus = [
        {
          mbu_code: mbu.code,
          mbu,
        },
      ];
    }

    // this.canAccessAllMbus depends on the selected user not the logged in one
    const canAccessAllMbus = this.credentialsService.can(PermissionName.AccessAllMbus);

    if (this.countriesAll) {
      this.countries = this.countriesAll.filter(
        (country) => canAccessAllMbus || country.is_available || country.code === this.userInput.country,
      );

      if (!this.userId && !this.duplicateUserId && !this.forProfile && !this.userInput.country) {
        const country = this.countries.find((country) => country.is_default);
        if (country) {
          // The dropdown component will process empty options after the value is set and reset the value
          setTimeout(() => {
            this.userInput.country = country.code;
            this.originalUserInput = JSON.stringify(this.userInput);
          }, 100);
        }
      }
    }

    if (this.localesAll) {
      this.locales = this.localesAll.filter(
        (locale) => canAccessAllMbus || locale.is_available || locale.code === this.userInput.locale,
      );

      if (!this.userId && !this.duplicateUserId && !this.forProfile && !this.userInput.locale) {
        const locale = this.locales.find((locale) => locale.is_default);
        if (locale) {
          // The dropdown component will process empty options after the value is set and reset the value
          setTimeout(() => {
            this.userInput.locale = locale.code;
            this.originalUserInput = JSON.stringify(this.userInput);
          }, 100);
        }
      }
    }

    if (this.mbus && this.roles && this.userInput.user_roles) {
      this.userInput.user_roles.forEach((userRole) => {
        if (userRole.role_id && !userRole.role) {
          userRole.role = this.roles.find((role) => role.id === userRole.role_id);
        }
        if (userRole.mbu_code && !userRole.mbu) {
          userRole.mbu = this.mbus.find((mbu) => mbu.code === userRole.mbu_code);
        }
      });
    }

    this.originalUserInput = JSON.stringify(this.userInput);
  }

  managerUserSelectionChanged(user: User) {
    this.resetErrorsAndWarnings('manager_user_id');

    this.userInput.manager_user_id = user && typeof user === 'object' ? user.id : null;
  }

  defaultUserMbuSelectionChanged(event?: string) {
    if (!this.userInput.user_mbus) {
      return;
    }

    this.userInput.user_mbus.forEach((userMbu) => (userMbu.is_default = event && userMbu.mbu_code === event));
  }

  dblClickRelation(type: 'mbu' | 'user_group' | 'role', relation: UserMbu | UserUserGroup | UserRole) {
    switch (type) {
      case 'mbu':
        this.openUserMbuModal(relation);
        break;
      case 'role':
        this.openUserRoleModal(relation);
        break;
      case 'user_group':
        this.openUserUserGroupModal(relation);
        break;
    }
  }

  async prepareRelationMenuItems(type: 'mbu' | 'user_group' | 'role', relation: UserMbu | UserUserGroup | UserRole) {
    this.relationMenuItems = [
      {
        label: await this.translateService.get('ui.app.user-form.user-form-component-ts.edit').toPromise(),
        icon: 'pi pi-pencil',
        command: () => {
          switch (type) {
            case 'mbu':
              this.openUserMbuModal(relation);
              break;
            case 'role':
              this.openUserRoleModal(relation);
              break;
            case 'user_group':
              this.openUserUserGroupModal(relation);
              break;
          }
        },
      },
      {
        label: await this.translateService.get('ui.app.user-form.user-form-component-ts.delete').toPromise(),
        icon: 'pi pi-trash',
        command: () => {
          switch (type) {
            case 'mbu':
              this.deleteUserMbu(relation);
              break;
            case 'role':
              this.deleteUserRole(relation);
              break;
            case 'user_group':
              this.deleteUserUserGroup(relation);
              break;
          }
        },
      },
    ];
  }

  openUserMbuModal(userMbu?: UserMbu) {
    this.currentUserMbu = userMbu || {
      mbu_code: null,
      area_manager_user_id: null,
      customer_number: null,
      is_default: null,
    };
    this.mbuSelection = (this.mbus || []).find((mbu) => mbu.code === this.currentUserMbu.mbu_code);
    this.areaManagerUserSelection = (this.areaManagerSuggestions || []).find(
      (areaManagerUser) => areaManagerUser.id === this.currentUserMbu.area_manager_user_id,
    );
    this.customerNumber = userMbu ? userMbu.customer_number : null;
    this.showRelationsModal = true;
  }

  openUserUserGroupModal(userUserGroup?: UserUserGroup) {
    this.currentUserUserGroup = userUserGroup || { user_group_id: null, mbu_code: null };
    this.userGroupSelection = (this.userGroups || []).find(
      (userGroup) => userGroup.id === this.currentUserUserGroup.user_group_id,
    );
    this.mbuSelection = (this.mbus || []).find((mbu) => mbu.code === this.currentUserUserGroup.mbu_code);
    this.showRelationsModal = true;
  }

  openUserRoleModal(userRole?: UserRole) {
    this.currentUserRole = userRole || { role_id: null, mbu_code: null };
    this.roleSelection = (this.roles || []).find((role) => role.id === this.currentUserRole.role_id);
    this.mbuSelection = (this.mbus || []).find((mbu) => mbu.code === this.currentUserRole.mbu_code);
    this.showRelationsModal = true;
  }

  saveUserRelation() {
    if (!this.validation) {
      this.validation = {};
    }
    if (!this.validation.errors) {
      this.validation.errors = {};
    }

    if (this.currentUserMbu) {
      if (!this.mbuSelection) {
        this.translateService
          .get('ui.app.user-form.user-form-component-ts.mbu-is-required')
          .toPromise()
          .then((label) => (this.validation.errors['mbu'] = [label]));
        return;
      }

      // Find another record with the same information
      if (
        this.userInput.user_mbus.find(
          (userMbu) =>
            (this.userInput.user_mbus.indexOf(this.currentUserMbu) === -1 || // the record to be saved is new
              userMbu !== this.currentUserMbu) && // the found record is different
            userMbu.mbu_code === this.mbuSelection.code,
        )
      ) {
        this.translateService
          .get('ui.app.user-form.user-form-component-ts.mbu-is-already-added')
          .toPromise()
          .then((label) => (this.validation.errors['mbu'] = [label]));
        return;
      }

      this.currentUserMbu.mbu = this.mbuSelection;
      this.currentUserMbu.mbu_code = this.currentUserMbu.mbu ? this.currentUserMbu.mbu.code : null;
      this.currentUserMbu.area_manager_user = this.areaManagerUserSelection;
      this.currentUserMbu.area_manager_user_id = this.currentUserMbu.area_manager_user
        ? this.currentUserMbu.area_manager_user.id
        : null;
      this.currentUserMbu.customer_number = this.customerNumber;

      if (!this.userInput.user_mbus) {
        this.userInput.user_mbus = [];
      }

      if (this.userInput.user_mbus.indexOf(this.currentUserMbu) === -1) {
        this.userInput.user_mbus.push(this.currentUserMbu);
      }
    }

    if (this.currentUserUserGroup) {
      if (!this.userGroupSelection) {
        this.translateService
          .get('ui.app.user-form.user-form-component-ts.group-is-required')
          .toPromise()
          .then((label) => (this.validation.errors['user_group'] = [label]));
        return;
      }

      // Find another record with the same information
      if (
        this.userInput.user_user_groups.find(
          (userUserGroup) =>
            (this.userInput.user_user_groups.indexOf(this.currentUserUserGroup) === -1 || // the record to be saved is new
              userUserGroup !== this.currentUserUserGroup) && // the found record is different
            userUserGroup.user_group_id === this.userGroupSelection.id &&
            userUserGroup.mbu_code === (this.mbuSelection ? this.mbuSelection.code : null),
        )
      ) {
        this.translateService
          .get('ui.app.user-form.user-form-component-ts.group-is-already-added')
          .toPromise()
          .then((label) => (this.validation.errors['user_group'] = [label]));
        return;
      }

      this.currentUserUserGroup.user_group = this.userGroupSelection;
      this.currentUserUserGroup.user_group_id = this.currentUserUserGroup.user_group
        ? this.currentUserUserGroup.user_group.id
        : null;
      this.currentUserUserGroup.mbu = this.mbuSelection;
      this.currentUserUserGroup.mbu_code = this.currentUserUserGroup.mbu ? this.currentUserUserGroup.mbu.code : null;

      if (!this.userInput.user_user_groups) {
        this.userInput.user_user_groups = [];
      }

      if (this.userInput.user_user_groups.indexOf(this.currentUserUserGroup) === -1) {
        this.userInput.user_user_groups.push(this.currentUserUserGroup);
      }
    }

    if (this.currentUserRole) {
      if (!this.roleSelection) {
        this.translateService
          .get('ui.app.user-form.user-form-component-ts.role-is-required')
          .toPromise()
          .then((label) => (this.validation.errors['role'] = [label]));
        return;
      }

      // Find another record with the same information
      if (
        this.userInput.user_roles.find(
          (userRole) =>
            (this.userInput.user_roles.indexOf(this.currentUserRole) === -1 || // the record to be saved is new
              userRole !== this.currentUserRole) && // the found record is different
            userRole.role_id === this.roleSelection.id &&
            userRole.mbu_code === (this.mbuSelection ? this.mbuSelection.code : null),
        )
      ) {
        this.translateService
          .get('ui.app.user-form.user-form-component-ts.role-is-already-added')
          .toPromise()
          .then((label) => (this.validation.errors['role'] = [label]));
        return;
      }
      this.currentUserRole.role = this.roleSelection;
      this.currentUserRole.role_id = this.currentUserRole.role ? this.currentUserRole.role.id : null;
      this.currentUserRole.mbu = this.mbuSelection;
      this.currentUserRole.mbu_code = this.currentUserRole.mbu ? this.currentUserRole.mbu.code : null;

      if (!this.userInput.user_roles) {
        this.userInput.user_roles = [];
      }

      if (this.userInput.user_roles.indexOf(this.currentUserRole) === -1) {
        this.userInput.user_roles.push(this.currentUserRole);
      }
    }

    this.cancelUserRelation();
  }

  cancelUserRelation() {
    this.showRelationsModal = false;

    this.currentUserMbu = null;
    this.currentUserUserGroup = null;
    this.currentUserRole = null;

    this.areaManagerUserSelection = null;
    this.mbuSelection = null;
    this.customerNumber = null;
    this.userGroupSelection = null;
    this.roleSelection = null;

    this.resetErrorsAndWarnings('mbu');
    this.resetErrorsAndWarnings('user_group');
    this.resetErrorsAndWarnings('role');
    this.resetErrorsAndWarnings('area_manager_user');
  }

  deleteUserMbu(userMbu: UserMbu) {
    if (!userMbu || !this.userInput.user_mbus) {
      return;
    }

    const index = this.userInput.user_mbus.indexOf(userMbu);

    if (index === -1) {
      return;
    }

    this.userInput.user_mbus.splice(index, 1);
  }

  deleteUserUserGroup(userUserGroup: UserUserGroup) {
    if (!userUserGroup || !this.userInput.user_user_groups) {
      return;
    }

    const index = this.userInput.user_user_groups.indexOf(userUserGroup);

    if (index === -1) {
      return;
    }

    this.userInput.user_user_groups.splice(index, 1);
  }

  deleteUserRole(userRole: UserRole) {
    if (!userRole || !this.userInput.user_roles) {
      return;
    }

    const index = this.userInput.user_roles.indexOf(userRole);

    if (index === -1) {
      return;
    }

    this.userInput.user_roles.splice(index, 1);
  }

  fileChosen(e: Event | FileList | File) {
    this.resetErrorsAndWarnings('profile_picture');

    this.profilePictureFile = null;
    this.profilePictureSrc = this.user ? this.user.profile_picture_url : '';
    this.profilePictureSrcSet = this.user ? this.user.profile_picture_srcset : '';
    this.profilePictureWidth = null;
    this.profilePictureHeight = null;

    if (!e) {
      return;
    }

    if (e instanceof File) {
      this.profilePictureFile = e;
    } else if (e instanceof FileList) {
      this.profilePictureFile = e.length ? e[0] : null;
    } else {
      const target = e.target as HTMLInputElement;
      if (target && target.files && target.files.length) {
        this.profilePictureFile = target.files[0];
      }
    }

    if (!this.profilePictureFile) {
      return;
    }

    this.profilePictureSrc = '';
    this.profilePictureSrcSet = '';
    this.profilePictureWidth = null;
    this.profilePictureHeight = null;

    const reader = new FileReader();
    reader.onload = (e) => (this.profilePictureSrc = e.target.result.toString());
    reader.readAsDataURL(this.profilePictureFile);

    const image = new Image();
    const objectUrl = URL.createObjectURL(this.profilePictureFile);
    image.onload = () => {
      this.profilePictureWidth = image.width;
      this.profilePictureHeight = image.height;
      URL.revokeObjectURL(objectUrl);
    };
    image.src = objectUrl;
  }

  resetErrorsAndWarnings(key: string, deep = false) {
    if (this.validation) {
      if (this.validation.errors) {
        delete this.validation.errors[key];

        if (deep) {
          Object.keys(this.validation.errors).forEach((keyPotential) => {
            if (keyPotential.indexOf(key) === 0) {
              delete this.validation.errors[keyPotential];
            }
          });
        }
      }

      if (this.validation.warnings) {
        delete this.validation.warnings[key];

        if (deep) {
          Object.keys(this.validation.warnings).forEach((keyPotential) => {
            if (keyPotential.indexOf(key) === 0) {
              delete this.validation.warnings[keyPotential];
            }
          });
        }
      }

      if (!this.validation.errors || !Object.keys(this.validation.errors).length) {
        this.validation.message = '';
      }
    }
  }

  save(forceDuplicate?: boolean) {
    if (this.isSaving) {
      return;
    }

    this.isSaving++;
    const userInput = { ...this.userInput, force_duplicate: forceDuplicate };
    const saver = this.forProfile
      ? this.apiAuthService.authSetMe(userInput)
      : this.userId
        ? this.apiUsersService.userUpdate(this.userId, userInput)
        : this.apiUsersService.userCreate(userInput);
    saver.pipe(untilDestroyed(this)).subscribe(
      (res) => {
        if (!this.user && res.data) {
          this.user = res.data;
        }

        this.validation = {};

        this.isSaving--;

        const done = (user: User) => {
          this.originalUserInput = JSON.stringify(this.userInput);

          this.saved.emit(user);
          this.titleService.pop();

          if (this.forProfile) {
            this.authenticationService.refreshProfile(user).then();
          }
        };

        if (this.profilePictureFile && (this.forProfile || this.userId)) {
          this.isSaving++;
          const uploader = this.forProfile
            ? this.apiAuthService.authProfilePicture(
                null,
                null,
                null,
                this.formattingService.convertNullToUndefined(this.profilePictureFile),
              )
            : this.apiUsersService.userProfilePicture(
                this.userId,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                this.formattingService.convertNullToUndefined(this.profilePictureFile),
              );
          uploader.pipe(untilDestroyed(this)).subscribe(
            (res2) => {
              this.isSaving--;

              done(res2.data);
            },
            (res2) => {
              this.validation = this.formattingService.explodeValidationMessages(res2 && res2.error ? res2.error : {});

              this.isSaving--;

              // If something prevented the process from completing, convert this to an edit, if started with created
              if (!this.forProfile && !this.userId && res.data && res.data.id) {
                this.userId = res.data.id;
              }
            },
          );
        } else {
          done(res.data);
        }
      },
      (res) => {
        if (res && res.status === 409) {
          this.formattingService
            .confirmForceDuplicate(() => this.save(true), res.error ? res.error.message : null)
            .then();
        } else {
          this.validation = this.formattingService.explodeValidationMessages(res && res.error ? res.error : {});
        }

        this.isSaving--;
      },
    );
  }

  async forget() {
    if (!this.forProfile) {
      return;
    }

    this.confirmationService.confirm({
      header: await this.translateService.get('ui.app.user-form.user-form-component-ts.forget').toPromise(),
      message: await this.translateService
        .get(
          'ui.app.user-form.user-form-component-ts.are-you-sure-you-want-to-forget-your-account-there-is-no-going-back',
        )
        .toPromise(),
      acceptLabel: await this.translateService.get('ui.app.user-form.user-form-component-ts.ok').toPromise(),
      rejectLabel: await this.translateService.get('ui.app.user-form.user-form-component-ts.cancel').toPromise(),
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isSaving++;
        const handler = () => this.isSaving--;
        this.apiAuthService
          .authForget()
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.authenticationService.logout(true).pipe(untilDestroyed(this)).subscribe(handler, handler);
          }, handler);
      },
    });
  }

  async cancel() {
    if (this.checkChanges && this.checkChanges()) {
      this.confirmationService.confirm({
        header: await this.translateService.get('ui.app.user-form.user-form-component-ts.cancel').toPromise(),
        message: await this.translateService
          .get('ui.app.user-form.user-form-component-ts.are-you-sure-you-want-to-cancel-you-have-unsaved-changes')
          .toPromise(),
        acceptLabel: await this.translateService.get('ui.app.user-form.user-form-component-ts.ok').toPromise(),
        rejectLabel: await this.translateService.get('ui.app.user-form.user-form-component-ts.cancel').toPromise(),
        acceptButtonStyleClass: 'p-button-warning',
        rejectButtonStyleClass: 'p-button-secondary',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.cancelled.emit();
          this.titleService.pop();
        },
      });

      return;
    }

    this.cancelled.emit();
    this.titleService.pop();
  }

  ngOnDestroy(): void {
    this.unloaderService.unregister(this.checkChanges);
  }
}
